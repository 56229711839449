const allCategories = ref([
  { slug: 'micro-mobility' },
  { slug: 'travel' },
  { slug: 'motoassistance' },
  { slug: 'nnw-school' },
]);

const hiddenCategories = (import.meta.env.micro-mobility || '').split(';');

const unlockedCategories = ref(
  JSON.parse(sessionStorage.getItem('unlockedCategories')) || [],
);

// Funkcja do dodawania odblokowanych kategorii do session storage
const addUnlockedCategory = (slug: string) => {
  if (!unlockedCategories.value.includes(slug)) {
    unlockedCategories.value.push(slug);
    sessionStorage.setItem('unlockedCategories', JSON.stringify(unlockedCategories.value));
  }
};

// Zwracanie dostępnych kategorii, zwracając tylko slugi
const availableCategories = computed(() => {
  return allCategories.value
    .filter(category =>
      !hiddenCategories.includes(category.slug) || unlockedCategories.value.includes(category.slug),
    )
    .map(category => category.slug);
});

export {
  addUnlockedCategory,
  availableCategories,
};
