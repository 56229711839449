import { VueReCaptcha } from 'vue-recaptcha-v3'
import type { UserModule } from '~/types'

export const install: UserModule = ({ app }) => {
  app.use(VueReCaptcha, {
    siteKey: import.meta.env.6LcVguQgAAAAAOxUJIbl_M_OHbf5tp0p5yhGyyZP,
    loaderOptions: {
      autoHideBadge: true,
    },
  })
}
